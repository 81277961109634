import React, { useEffect, useState } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import {
  HiDocumentText,
  HiMiniUserGroup,
  HiOutlineArrowSmallLeft,
} from "react-icons/hi2";
import StepsList from "../../components/StepsList/StepsList";
import { HiMiniUserCircle } from "react-icons/hi2";
import { HiMiniMapPin } from "react-icons/hi2";
import { HiAcademicCap } from "react-icons/hi2";
import { HiMiniBriefcase } from "react-icons/hi2";
import SelectElement from "../../components/SelectElement/MainSelect";
import Input from "../../components/Input/input";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { ufSelect } from "../../services/selectService/uf";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import {
  getStudentBasicInfos,
  putStudentBasicInfos,
} from "../../services/studentService/student.service";
import { nationalitysSelect } from "../../services/selectService/nationalitys";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import { viaCep } from "../../services/viaCep/viaCep.service";
import { Link } from "react-router-dom";
import moment from "moment";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import { certifier } from "../../mocks/certifier";

function BasicInfosPage() {
  const [studentInfos, setStudentInfos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [mobileScreen, setMobileScreen] = useState(1);
  const [windowWidth, setWindowWidth] = useState(0);
  // const [windowHeight, setWindowHeight] = useState(0);
  const [navBar, setNavBar] = useState(0);
  const [step, setStep] = useState(1);
  const [wrongZipCode, setWrongZipCode] = useState(false);
  const [steps, setSteps] = useState([]);
  const [navBarHeader, setNavBarHeader] = useState(0);

  const [basicInfosFormStep1, setBasicInfosFormStep1] = useState({
    name: "",
    email: "",
    phone: "",
    cpf: "",
    rg: "",
    organIssuing: "",
    uf: "",
  });

  const [basicInfosFormStep2, setBasicInfosFormStep2] = useState({
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
  });

  const [basicInfosFormStep3, setBasicInfosFormStep3] = useState({
    nameFather: "",
    nameMother: "",
    birthDate: "",
    homeTown: "",
    homeState: "",
    nationality: "",
    othersBirthState: "",
  });

  const [basicInfosFormStep4, setBasicInfosFormStep4] = useState({
    graduationCourse: "",
    educationalInstitution: "",
  });

  const [basicInfosFormStep5, setBasicInfosFormStep5] = useState({
    howDidYouMeet: "",
    instagram: "",
    professional: "",
    professionalSituation: "",
    reason: "",
    salaryRange: "",
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // setWindowHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchStudentCourses = async () => {
      try {
        const student = await getStudentInfos();
        const studentData = await getStudentBasicInfos(student);
        if (studentData.status === 200) {
          setStudentInfos(student);

          let newStudentData1 = studentData.data[1];
          if (newStudentData1.phone !== "") {
            const formattedPhone = newStudentData1.phone
              .trim()
              .replace(/\D/g, "")
              .replace(
                /^(\d{0,2})(\d{0,5})(\d{0,4}).*/,
                (match, p1, p2, p3) => {
                  return `${p1 ? "(" + p1 : ""}${p2 ? ") " + p2 : ""}${
                    p3 ? "-" + p3 : ""
                  }`;
                }
              )
              .substring(0, 15);
            newStudentData1.phone = formattedPhone;
          }

          setBasicInfosFormStep1(newStudentData1);
          setBasicInfosFormStep2(studentData.data[2]);

          const nationalityConvert =
            studentData.data[3].nationality === "Brasileiro"
              ? "Brasileira"
              : studentData.data[3].nationality;
          let newStudentData3 = studentData.data[3];
          newStudentData3.nationality = nationalityConvert;
          if (String(newStudentData3.birthDate).includes("T")) {
            newStudentData3.birthDate = moment(
              newStudentData3.birthDate
            ).format("YYYY-MM-DD");
          }
          if (!ufSelect.find((uf) => uf.value === newStudentData3.homeState)) {
            if (newStudentData3.homeState) {
              newStudentData3.othersBirthState = newStudentData3.homeState;
              newStudentData3.homeState = "Outros";
            }
          }

          setBasicInfosFormStep3(newStudentData3);
          setBasicInfosFormStep4(studentData.data[4]);
          setBasicInfosFormStep5(studentData.data[5]);
        } else {
          Toastify("error", "Erro ao buscar informações de todos os cursos.");
        }
        const navBar = document.getElementById("mobile-navigation");
        if (navBar) setNavBar(navBar.offsetHeight);

        const navBarHeader = document.getElementById("mainHeader");

        if (navBarHeader) setNavBarHeader(navBarHeader.offsetHeight);
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar os cursos.");
        console.error("Erro ao buscar os cursos: ", error);
      }
      setIsLoading(false);
    };

    fetchStudentCourses();
  }, []);

  const displayStep = (step) => {
    let stepsData = [
      {
        content: (
          <div className="flex flex-col">
            <span className="font-semibold">Dados Pessoais</span>
            <span className="text-xs">Sobre você.</span>
          </div>
        ),
        activated: true,
        icon: <HiMiniUserCircle />,
      },
      {
        content: (
          <div className="flex flex-col">
            <span className="font-semibold">Endereço</span>
            <span className="text-xs">Sobre sua residência.</span>
          </div>
        ),
        activated: false,
        icon: <HiMiniMapPin />,
      },
      {
        content: (
          <div className="flex flex-col">
            <span className="font-semibold">Nascimento e Filiação</span>
            <span className="text-xs">
              Sobre seus pais e local de nascimento.
            </span>
          </div>
        ),
        activated: false,
        icon: <HiMiniUserGroup />,
      },
      {
        content: (
          <div className="flex flex-col">
            <span className="font-semibold">Escolaridade</span>
            <span className="text-xs">Sobre sua graduação.</span>
          </div>
        ),
        activated: false,
        icon: <HiAcademicCap />,
      },
      {
        content: (
          <div className="flex flex-col">
            <span className="font-semibold">Carreira</span>
            <span className="text-xs">Sobre sua carreira.</span>
          </div>
        ),
        activated: false,
        icon: <HiMiniBriefcase />,
      },
      {
        content: (
          <div className="flex flex-col">
            <span className="font-semibold">Contrato</span>
            <span className="text-xs">Assinatura do contrato.</span>
          </div>
        ),
        activated: false,
        icon: <HiDocumentText />,
      },
    ];

    for (let index = 0; index < step; index++) {
      stepsData[index].activated = true;
    }

    setSteps(stepsData);
  };

  function isEighteenOrOlder(birthDate) {
    const birthDateObject = new Date(birthDate);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    return birthDateObject <= eighteenYearsAgo;
  }

  const UFs = ufSelect;

  const Nationalitys = nationalitysSelect;

  const handleChangeStep1 = (e) => {
    let name, value;
    if (e.hasOwnProperty("value") && !e.target) {
      name = "uf";
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "phone") {
      let newValue = value
        .trim()
        .replace(/\D/g, "")
        .replace(/^(\d{0,2})(\d{0,5})(\d{0,4}).*/, (match, p1, p2, p3) => {
          return `${p1 ? "(" + p1 : ""}${p2 ? ") " + p2 : ""}${
            p3 ? "-" + p3 : ""
          }`;
        })
        .substring(0, 15);

      setBasicInfosFormStep1({ ...basicInfosFormStep1, [name]: newValue });
    } else if (name === "rg") {
      setBasicInfosFormStep1({
        ...basicInfosFormStep1,
        [name]: value.trim().toUpperCase(),
      });
    } else if (name === "organIssuing") {
      setBasicInfosFormStep1({
        ...basicInfosFormStep1,
        [name]: value.toUpperCase().trimStart().replace(/\d+/g, ""),
      });
    } else if (name === "name") {
      let formattedValue = value
        .trimStart()
        .replace(/[^a-zA-Z\s]/g, "")
        .replace(/\s+/g, " ")
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

      setBasicInfosFormStep1({
        ...basicInfosFormStep1,
        [name]: formattedValue,
      });
    } else {
      setBasicInfosFormStep1({ ...basicInfosFormStep1, [name]: value });
    }
  };

  const handleChangeStep2 = async (e) => {
    let name, value;
    if (e.hasOwnProperty("value") && !e.target) {
      name = "state";
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "zipCode") {
      setBasicInfosFormStep2({
        ...basicInfosFormStep2,
        [name]: (() => {
          let cleanedValue = value
            .trim()
            .replace(/[^0-9]/g, "")
            .slice(0, 8);

          if (cleanedValue.length >= 6) {
            cleanedValue = `${cleanedValue.slice(0, 5)}-${cleanedValue.slice(
              5
            )}`;
          }

          if (cleanedValue.endsWith("-")) {
            cleanedValue = cleanedValue.slice(0, -1);
          }

          return cleanedValue;
        })(),
      });
      if (value.length === 9) {
        setIsLoading(true);
        try {
          const cep = value.replace("-", "");
          const response = await viaCep(cep);
          if (response.data.erro) {
            Toastify("error", "CEP não encontrado.");
            setWrongZipCode(true);
            return;
          }
          if (response.status === 200) {
            setWrongZipCode(false);
            setBasicInfosFormStep2({
              ...basicInfosFormStep2,
              [name]: value,
              street: response.data.logradouro,
              district: response.data.bairro,
              city: response.data.localidade,
              state: response.data.uf,
            });
          }
        } catch (error) {
          console.error("Erro ao buscar o CEP", error);
          Toastify(
            "error",
            "Erro ao buscar o CEP. Tente novamente mais tarde."
          );
          setWrongZipCode(true);
        } finally {
          setIsLoading(false);
        }
      }
    } else if (name === "state") {
      setBasicInfosFormStep2({
        ...basicInfosFormStep2,
        [name]: value,
      });
    } else {
      let formattedValue = value
        .trimStart()
        .replace(/\s+/g, " ")
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

      setBasicInfosFormStep2({
        ...basicInfosFormStep2,
        [name]: formattedValue,
      });
    }
  };

  const handleChangeStep3 = (e, valueName) => {
    let name, value;
    if (e.hasOwnProperty("value") && !e.target) {
      name = valueName;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setBasicInfosFormStep3({ ...basicInfosFormStep3, [name]: value });
  };

  const handleChangeStep4 = (e) => {
    let name, value;
    if (e.hasOwnProperty("value") && !e.target) {
      name = "uf";
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setBasicInfosFormStep4({ ...basicInfosFormStep4, [name]: value });
  };

  const handleChangeStep5 = (e, valueName) => {
    setBasicInfosFormStep5({ ...basicInfosFormStep5, [valueName]: e });
  };

  const handleSetMobileScreen = async () => {
    setMobileScreen(2);
    window.scrollTo(0, 0);
  };

  const handleBack = async () => {
    window.scrollTo(0, 0);
    setStep(step - 1);
  };

  const handleUpdate = async (sandboxStep) => {
    if (step < 6) {
      setIsLoading(true);
      try {
        let data = null;

        if (!(sandboxStep === `step1`)) {
          switch (step) {
            case 1:
              if (
                !basicInfosFormStep1.name ||
                !basicInfosFormStep1.email ||
                !basicInfosFormStep1.phone ||
                basicInfosFormStep1.phone.length !== 15 ||
                !basicInfosFormStep1.cpf ||
                !basicInfosFormStep1.rg ||
                !basicInfosFormStep1.organIssuing ||
                !basicInfosFormStep1.uf
              ) {
                Toastify("error", "Preencha todos os campos.");
                setIsLoading(false);
                return;
              }
              data = { ...basicInfosFormStep1 };
              data.phone = String(basicInfosFormStep1.phone).replace(
                /[()-\s]/g,
                ""
              );
              break;

            case 2:
              if (
                !basicInfosFormStep2.zipCode ||
                basicInfosFormStep2.zipCode.length !== 9 ||
                wrongZipCode ||
                !basicInfosFormStep2.street ||
                !basicInfosFormStep2.number ||
                !basicInfosFormStep2.district ||
                !basicInfosFormStep2.city ||
                !basicInfosFormStep2.state
              ) {
                Toastify("error", "Preencha todos os campos.");
                setIsLoading(false);
                return;
              }
              data = basicInfosFormStep2;
              break;

            case 3:
              if (
                !basicInfosFormStep3.nameFather ||
                !basicInfosFormStep3.nameMother ||
                !basicInfosFormStep3.birthDate ||
                !basicInfosFormStep3.homeTown ||
                !basicInfosFormStep3.nationality ||
                !basicInfosFormStep3.homeState ||
                (basicInfosFormStep3.homeState === "Outros" &&
                  !basicInfosFormStep3.othersBirthState)
              ) {
                Toastify("error", "Preencha todos os campos.");
                setIsLoading(false);
                return;
              }
              if (!isEighteenOrOlder(basicInfosFormStep3.birthDate)) {
                Toastify("error", "Você deve ter no mínimo 18 anos.");
                setIsLoading(false);
                return;
              }

              data = { ...basicInfosFormStep3 };
              data.homeState =
                data.homeState === "Outros"
                  ? data.othersBirthState.trim()
                  : data.homeState;

              const formattedBirthDate = moment
                .utc(String(data.birthDate).split(`T`)[0])
                .add(3, "hours")
                .format();

              data.nationality =
                data.nationality === "Brasileira"
                  ? "Brasileiro"
                  : data.nationality;

              data.birthDate = formattedBirthDate;
              break;

            case 4:
              if (
                !basicInfosFormStep4.graduationCourse ||
                !basicInfosFormStep4.educationalInstitution
              ) {
                Toastify("error", "Preencha todos os campos.");
                setIsLoading(false);
                return;
              }
              data = basicInfosFormStep4;
              break;

            case 5:
              data = basicInfosFormStep5;
              break;
            default:
              break;
          }
        } else {
          data = {
            ...basicInfosFormStep1,
            rg: "20.999.000-55",
            organIssuing: "SSP",
            uf: "MG",
          };
          data.phone = String(basicInfosFormStep1.phone).replace(
            /[()-\s]/g,
            ""
          );
        }

        let update = await putStudentBasicInfos(studentInfos, data, step);
        if (update.status === 200) {
          if (step === 5) {
            let localToken = await getStudentInfos();
            localToken.firstAccess = false;
            localToken.completed = true;
            const ciphertext = await CryptoService(
              localToken,
              "encrypt-crypto"
            );
            localStorage.setItem("user", ciphertext);

            window.location.href = "/user/contracts";
          } else {
            setStep(step + 1);
          }
        } else {
          Toastify("error", "Erro ao Atualizar.");
        }
      } catch (err) {
        console.error(err);
        Toastify("error", "Erro ao Atualizar #2.");
      }
      window.scrollTo(0, 0);
      setIsLoading(false);
    }
  };

  // const UFsWithOthersOption = () => {
  //   let allUpdatedUFS = [];
  //   UFs.map((UF) => {
  //     allUpdatedUFS.push({
  //       fromForm: "birthState",
  //       value: UF.value,
  //       label: UF.label,
  //     });
  //   });
  //   allUpdatedUFS.push({
  //     fromForm: "birthState",
  //     value: "Outros",
  //     label: "Outros",
  //   });
  //   return allUpdatedUFS;
  // };

  const UFsWithOthersOption = () => {
    let allUpdatedUFS = UFs.map((UF) => ({
      fromForm: "birthState",
      value: UF.value,
      label: UF.label,
    }));

    allUpdatedUFS.push({
      fromForm: "birthState",
      value: "Outros",
      label: "Outros",
    });

    return allUpdatedUFS;
  };

  useEffect(() => {
    displayStep(step);
  }, [step]);

  function formatCPF(cpf) {
    cpf = String(cpf);
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  // Sandbox Function to AutoComplete with FakeData
  function handleSandboxAutoComplete() {
    setBasicInfosFormStep3({
      nameFather: "Paulo Sobrinho",
      nameMother: "Maria Silveira",
      birthDate: "2005-05-31",
      homeTown: "Alagoiás",
      homeState: "GO",
      nationality: "Canadense",
      othersBirthState: "",
    });
    setBasicInfosFormStep4({
      graduationCourse: "Engenharia de Software",
      educationalInstitution: "UFMA",
    });
    handleUpdate(`step1`);
  }
  return (
    <main
      className={`flex flex-col w-full overflow-x-hidden bg-backgroundOne min-h-screen items-center text-textPrimary`}
    >
      <HeaderBar
        courseNavigation={false}
        mobileMainText="Informações Básicas"
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar actualPage="Perfil" />
      <LoadingSection isLoading={isLoading} />
      {studentInfos && (
        <section
          className={`flex w-full mt-9 mb-9 pl-0 h-full max-w-[1170px] bg-backgroundTwo justify-center min-h-[600px] rounded-xl max-mc:rounded-none max-mc:flex-col overflow-hidden max-mb:m-0`}
        >
          {(mobileScreen === 1 || windowWidth > 960) && (
            <div
              style={{
                paddingTop: windowWidth < 960 ? navBarHeader : 0,
                paddingBottom: windowWidth < 960 ? navBar : 0,
              }}
              className="w-[65%] max-mc:w-full"
            >
              <div className="flex p-32 py-16 relative min-h-[75vh] w-full max-mc:p-8">
                <div className="flex flex-col w-full h-full justify-between">
                  <div className="flex flex-col gap-y-8 w-full h-fit">
                    <div className="flex flex-col gap-y-4">
                      <h1 className="text-5xl text-textPrimary font-bold leading-tight max-mc:text-4xl">
                        {String(studentInfos?.name).split(" ")[0]}
                        {studentInfos && studentInfos.completed
                          ? ", gerencie suas informações"
                          : ", seja bem-vindo"}
                      </h1>
                      <span className="text-textSecondary text-sm">
                        Precisamos que preencha as últimas informações para
                        garantirmos que todos os seus documentos acadêmicos
                        sejam emitidos corretamente.
                      </span>
                    </div>
                    <StepsList steps={steps} />
                    <div
                      className={`w-full max-mc:flex mb-8 hidden max-mc:justify-end max-mc:mb-0`}
                      style={{
                        paddingBottom: windowWidth < 960 ? navBar : 0,
                      }}
                    >
                      <ButtonPrimary
                        verifyOnClick={handleSetMobileScreen}
                        textButton="Próximo"
                        mobileWidth="48%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(mobileScreen === 2 || windowWidth > 960) && (
            <>
              <div
                style={{
                  paddingTop: windowWidth < 960 ? navBarHeader : 0,
                  paddingBottom: windowWidth < 960 ? navBar : 0,
                }}
                className="w-[38%] max-mc:w-full bg-backgroundThree"
              >
                {/* STEP 1 */}
                {step === 1 && (
                  <div className="flex min-h-[85vh] flex-col h-full p-16 max-mc:p-8 max-mc:w-full bg-backgroundThree">
                    <div>
                      <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                        Passo 1 de 6
                        {process.env.REACT_APP_API_ENVIRONMENT === "sandbox" ? (
                          <span
                            className="cursor-pointer"
                            onClick={handleSandboxAutoComplete}
                          >
                            --!--
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                      <span className="text-textPrimary text-sm">
                        Dados pessoais.
                      </span>
                    </div>
                    <div className="my-8 flex flex-col gap-y-8 pb-12 max-mc:pb-6">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">RA *</label>
                        <Input
                          disabled
                          type="text"
                          name="RA"
                          value={basicInfosFormStep1.RA}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Nome Completo *
                        </label>
                        <Input
                          type="text"
                          name="name"
                          value={basicInfosFormStep1.name}
                          onChange={handleChangeStep1}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">E-mail *</label>
                        <Input
                          type="email"
                          name="email"
                          value={basicInfosFormStep1.email}
                          onChange={handleChangeStep1}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">Celular *</label>
                        <Input
                          type="text"
                          name="phone"
                          value={basicInfosFormStep1.phone}
                          onChange={handleChangeStep1}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">CPF *</label>
                        <Input
                          disabled
                          type="text"
                          name="cpf"
                          value={formatCPF(basicInfosFormStep1.cpf)}
                          onChange={handleChangeStep1}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">RG *</label>
                        <Input
                          type="text"
                          name="rg"
                          value={basicInfosFormStep1.rg}
                          onChange={handleChangeStep1}
                        />
                      </div>
                      <div className="flex gap-x-6 w-full">
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">
                            Órgão Expedidor *
                          </label>
                          <Input
                            type="text"
                            name="organIssuing"
                            value={basicInfosFormStep1.organIssuing}
                            onChange={handleChangeStep1}
                          />
                        </div>
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">UF *</label>
                          <SelectElement
                            options={UFs}
                            disableGroups
                            value={basicInfosFormStep1.uf}
                            onChange={handleChangeStep1}
                          />
                        </div>
                      </div>
                      <div className="flex gap-x-6 w-full mb-8 justify-end">
                        <ButtonPrimary
                          verifyOnClick={handleUpdate}
                          textButton="Próximo"
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth="calc(50% - 12px)"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* STEP 2 */}
                {step === 2 && (
                  <div className="flex min-h-[85vh] flex-col max-mc:p-8 h-full p-16 max-mc:w-full bg-backgroundThree">
                    <div>
                      <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                        Passo 2 de 6
                        {process.env.REACT_APP_API_ENVIRONMENT === "sandbox" ? (
                          <span
                            className="cursor-pointer"
                            onClick={handleUpdate}
                          >
                            --!--
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                      <span className="text-textPrimary text-sm">Endereço</span>
                    </div>

                    <div className="my-8 flex flex-col gap-y-8 pb-12 max-mc:pb-6">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">CEP *</label>
                        <Input
                          type="text"
                          name="zipCode"
                          value={basicInfosFormStep2.zipCode}
                          onChange={handleChangeStep2}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full h-full">
                        <label className="span-small-white">Rua *</label>
                        <Input
                          type="text"
                          name="street"
                          value={basicInfosFormStep2.street}
                          onChange={handleChangeStep2}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>

                      <div className="flex gap-x-4 w-full">
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">Número *</label>
                          <Input
                            type="text"
                            name="number"
                            value={basicInfosFormStep2.number}
                            onChange={handleChangeStep2}
                            optionalIcon={<HiMiniMapPin />}
                          />
                        </div>
                        <div className="flex flex-col gap-y-3 w-full h-full">
                          <label className="span-small-white">
                            Complemento
                          </label>
                          <Input
                            type="text"
                            name="complement"
                            value={basicInfosFormStep2.complement}
                            onChange={handleChangeStep2}
                            optionalIcon={<HiMiniMapPin />}
                            limitCharacter={30}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">Bairro *</label>
                        <Input
                          type="text"
                          name="district"
                          value={basicInfosFormStep2.district}
                          onChange={handleChangeStep2}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">Cidade *</label>
                        <Input
                          type="text"
                          name="city"
                          value={basicInfosFormStep2.city}
                          onChange={handleChangeStep2}
                          optionalIcon={<HiMiniMapPin />}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">Estado *</label>
                        <SelectElement
                          name="state"
                          options={UFs}
                          disableGroups
                          value={basicInfosFormStep2.state}
                          onChange={handleChangeStep2}
                        />
                      </div>
                      <div className="flex gap-x-6 mb-8 w-full pb-1 max-mc:pb-0">
                        <ButtonPrimary
                          optionalBackground={certifier.colors.buttons.buttonTertiary}
                          optionalTextColor={certifier
                            .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                          verifyOnClick={handleBack}
                          textButton="Anterior"
                          leftIcon
                          mobileWidth="calc(50% - 12px)"
                          desktopWidth="calc(50% - 12px)"
                          optionalMovingIcon={<HiOutlineArrowSmallLeft />}
                        />
                        <ButtonPrimary
                          verifyOnClick={handleUpdate}
                          textButton="Próximo"
                          mobileWidth="calc(50% - 12px)"
                          desktopWidth="calc(50% - 12px)"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* STEP 3 */}
                {step === 3 && (
                  <div className="flex min-h-[85vh] flex-col max-mc:p-8 h-full p-16 max-mc:w-full bg-backgroundThree">
                    <div>
                      <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                        Passo 3 de 6
                        {process.env.REACT_APP_API_ENVIRONMENT === "sandbox" ? (
                          <span
                            className="cursor-pointer"
                            onClick={handleUpdate}
                          >
                            --!--
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                      <span className="text-textPrimary text-sm">
                        Nascimento e Filiação.
                      </span>
                    </div>

                    <div className="my-8 flex flex-col gap-y-8 pb-12 max-mc:pb-6">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Nome do Pai *
                        </label>
                        <Input
                          type="text"
                          name="nameFather"
                          value={basicInfosFormStep3.nameFather}
                          onChange={handleChangeStep3}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Nome do Mãe *
                        </label>
                        <Input
                          type="text"
                          name="nameMother"
                          value={basicInfosFormStep3.nameMother}
                          onChange={handleChangeStep3}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Data de Nascimento *
                        </label>
                        <Input
                          type="date"
                          name="birthDate"
                          value={basicInfosFormStep3.birthDate}
                          onChange={handleChangeStep3}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Cidade Natal *
                        </label>
                        <Input
                          type="text"
                          name="homeTown"
                          value={basicInfosFormStep3.homeTown}
                          onChange={handleChangeStep3}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Estado Natal *
                        </label>
                        <SelectElement
                          name="homeState"
                          options={UFsWithOthersOption()}
                          disableGroups
                          value={basicInfosFormStep3.homeState}
                          onChange={(e) => handleChangeStep3(e, `homeState`)}
                        />
                      </div>
                      {basicInfosFormStep3.homeState === "Outros" && (
                        <div className="flex flex-col gap-y-3 w-full">
                          <label className="span-small-white">
                            Estado Natal
                          </label>
                          <Input
                            type="text"
                            name="othersBirthState"
                            value={basicInfosFormStep3.othersBirthState}
                            onChange={handleChangeStep3}
                          />
                        </div>
                      )}
                      <div className="flex flex-col gap-y-3 w-full h-full">
                        <label className="span-small-white">
                          Nacionalidade *
                        </label>
                        <SelectElement
                          options={Nationalitys}
                          disableGroups
                          name="nationality"
                          value={basicInfosFormStep3.nationality}
                          onChange={(e) => handleChangeStep3(e, `nationality`)}
                        />
                      </div>
                      <div className="flex gap-x-6 mb-8 w-full">
                        <ButtonPrimary
                          optionalBackground={certifier.colors.buttons.buttonTertiary}
                          optionalTextColor={certifier
                            .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                          verifyOnClick={handleBack}
                          textButton="Anterior"
                          leftIcon
                          mobileWidth="calc(50% - 12px)"
                          desktopWidth="calc(50% - 12px)"
                          optionalMovingIcon={<HiOutlineArrowSmallLeft />}
                        />
                        <ButtonPrimary
                          verifyOnClick={handleUpdate}
                          textButton="Próximo"
                          mobileWidth="calc(50% - 12px)"
                          desktopWidth="calc(50% - 12px)"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* STEP 4 */}
                {step === 4 && (
                  <div className="flex min-h-[85vh] flex-col max-mc:p-8 h-full p-16 max-mc:w-full bg-backgroundThree">
                    <div>
                      <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                        Passo 4 de 6
                        {process.env.REACT_APP_API_ENVIRONMENT === "sandbox" ? (
                          <span
                            className="cursor-pointer"
                            onClick={handleUpdate}
                          >
                            --!--
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                      <span className="text-textPrimary text-sm">Escolaridade</span>
                    </div>

                    <div className="my-8 flex flex-col gap-y-8 pb-12 max-mc:pb-6">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Curso de Graduação *
                        </label>
                        <Input
                          type="text"
                          name="graduationCourse"
                          value={basicInfosFormStep4.graduationCourse}
                          onChange={handleChangeStep4}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Nome da Instituição *
                        </label>
                        <Input
                          type="text"
                          name="educationalInstitution"
                          value={basicInfosFormStep4.educationalInstitution}
                          onChange={handleChangeStep4}
                        />
                      </div>

                      <div className="flex gap-x-6 mb-8 w-full">
                        <ButtonPrimary
                          optionalBackground={certifier.colors.buttons.buttonTertiary}
                          optionalTextColor={certifier
                            .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                          verifyOnClick={handleBack}
                          textButton="Anterior"
                          leftIcon
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth="calc(50% - 12px)"
                          optionalMovingIcon={<HiOutlineArrowSmallLeft />}
                        />
                        <ButtonPrimary
                          verifyOnClick={handleUpdate}
                          textButton="Próximo"
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth="calc(50% - 12px)"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* STEP 5 */}
                {step === 5 && (
                  <div className="flex min-h-[85vh] flex-col max-mc:p-8 h-full p-16 max-mc:w-full bg-backgroundThree">
                    <div>
                      <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                        Passo 5 de 6
                        {process.env.REACT_APP_API_ENVIRONMENT === "sandbox" ? (
                          <span
                            className="cursor-pointer"
                            onClick={handleUpdate}
                          >
                            --!--
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                      <span className="text-textPrimary text-sm">Carreira</span>
                    </div>

                    <div className="my-8 flex flex-col gap-y-8 pb-12 max-mc:pb-6">
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Situação profissional
                        </label>
                        <SelectElement
                          name="professionalSituation"
                          options={[
                            {value: "Servidor Público", label: "Servidor Público"},
                            {value: "Profissional Privado", label: "Profissional Privado"},
                            {value: "Empresário", label: "Empresário"},
                            {value: "Desempregado", label: "Desempregado"},
                          ]}
                          disableGroups
                          value={basicInfosFormStep5.professionalSituation}
                          onChange={(e) => handleChangeStep5(e.label, `professionalSituation`)}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Profissão
                        </label>
                        <Input
                          type="text"
                          name="professional"
                          value={basicInfosFormStep5.professional}
                          onChange={(e) => handleChangeStep5(e.target.value, `professional`)}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Faixa salarial
                        </label>
                        <SelectElement
                          name="salaryRange"
                          options={[
                            {value: "Até R$719", label: "Até R$719"},
                            {value: "De R$720 a R$1.749", label: "De R$720 a R$1.749"},
                            {value: "De R$1.750 a R$3.084", label: "De R$1.750 a R$3.084"},
                            {value: "De R$3.085 a R$5.640", label: "De R$3.085 a R$5.640"},
                            {value: "De R$5.641 a R$11.278", label: "De R$5.641 a R$11.278"},
                            {value: "De R$11.279 a R$25.554", label: "De R$11.279 a R$25.554"},
                            {value: "Acima de R$25.555", label: "Acima de R$25.555"},
                            {value: "Não desejo informar", label: "Não desejo informar"},
                          ]}
                          disableGroups
                          value={basicInfosFormStep5.salaryRange}
                          onChange={(e) => handleChangeStep5(e.label, `salaryRange`)}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Por que decidiu fazer uma pós?
                        </label>
                        <SelectElement
                          name="reason"
                          options={[
                            {value: "Quero mudar minha área de atuação", label: "Quero mudar minha área de atuação"},
                            {value: "Quero ganhar um aumento salarial", label: "Quero ganhar um aumento salarial"},
                            {value: "Quero adquirir novos conhecimentos", label: "Quero adquirir novos conhecimentos"},
                            {value: "Quero passar em um concurso público", label: "Quero passar em um concurso público"},
                            {value: "Quero conseguir um emprego em uma empresa", label: "Quero conseguir um emprego em uma empresa"},
                            {value: "Quero fazer networking", label: "Quero fazer networking"},
                            {value: "Nenhuma das alternativas", label: "Nenhuma das alternativas"},
                          ]}
                          disableGroups
                          value={basicInfosFormStep5.reason}
                          onChange={(e) => handleChangeStep5(e.label, `reason`)}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Como conheceu {certifier.generalSettings.grammatical.pronoun} {certifier.generalSettings.shortName}?
                        </label>
                        <SelectElement
                          name="howDidYouMeet"
                          options={[
                            {value: "Amigos de Trabalho", label: "Amigos de Trabalho"},
                            {value: "Familiares", label: "Familiares"},
                            {value: "Google", label: "Google"},
                            {value: "Youtube", label: "Youtube"},
                            {value: "Linkedin", label: "Linkedin"},
                            {value: "Facebook", label: "Facebook"},
                            {value: "Instagram", label: "Instagram"},
                            {value: "Televisão", label: "Televisão"},
                          ]}
                          disableGroups
                          value={basicInfosFormStep5.howDidYouMeet}
                          onChange={(e) => handleChangeStep5(e.label, `howDidYouMeet`)}
                        />
                      </div>
                      <div className="flex flex-col gap-y-3 w-full">
                        <label className="span-small-white">
                          Instagram
                        </label>
                        <Input
                          type="text"
                          name="instagram"
                          value={basicInfosFormStep5.instagram}
                          onChange={(e) => handleChangeStep5(e.target.value, `instagram`)}
                        />
                      </div>

                      <div className="flex gap-x-6 mb-8 w-full">
                        <ButtonPrimary
                          optionalBackground={certifier.colors.buttons.buttonTertiary}
                          optionalTextColor={certifier
                            .colors.buttons.buttonTertiary_darkTheme ? "#fff" : "#000"}
                          verifyOnClick={handleBack}
                          textButton="Anterior"
                          leftIcon
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth="calc(50% - 12px)"
                          optionalMovingIcon={<HiOutlineArrowSmallLeft />}
                        />
                        <ButtonPrimary
                          verifyOnClick={handleUpdate}
                          textButton="Próximo"
                          desktopWidth="calc(50% - 12px)"
                          mobileWidth="calc(50% - 12px)"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* STEP 6 */}
                {step === 6 && (
                  <div className="flex min-h-[85vh] max-mc:p-8 flex-col h-full p-16 max-mc:w-full bg-backgroundThree">
                    <div>
                      <h1 className="text-3xl text-textPrimary font-bold leading-[1.35]">
                        Passo 6 de 6
                      </h1>
                      <span className="text-textPrimary text-sm">
                        Assinatura do contrato.
                      </span>
                    </div>

                    <div className="my-8 flex flex-col gap-y-8 pb-12 max-mc:pb-6">
                      <div className="flex gap-x-6 w-full">
                        <Link to="/user/contracts">
                          <ButtonPrimary
                            verifyOnClick={handleUpdate}
                            textButton="Ir para o Contrato"
                            desktopWidth="calc(50% - 12px)"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </section>
      )}
    </main>
  );
}

export default verifyAuth(BasicInfosPage);
