import React, { useEffect, useState, useRef } from "react";
import verifyAuth from "../../services/verifyAuth/verifyAuth";
import ListCourses from "../../components/ListCourses/ListCourses";
import { useParams } from "react-router-dom";
import {
  getAllAvailableCourses,
  getCoursesRecommendedRequest,
  getNextStepsRequest
} from "../../services/dashboardService/dashboard.service";
import LoadingSection from "../../components/LoadingElements/LoadingSection";
import { Toastify } from "../../components/Toastify/Toastify";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";
import MobileNavigationBar from "../../components/MobileNavigationBar/MobileNavigationBar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./allAvailableCoursesPage.css";
import { certifier } from "../../mocks/certifier";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import DiscoveryCourse from "../../components/DiscoveryCourse/DiscoveryCourseInput/DiscoveryCourse";
import { getAllStudentCourses } from "../../services/courseService/course.service";

const options = [
  { name: "Todos", alias: "all" },
  { name: "Educação", alias: "educacao" },
  { name: "Saúde", alias: "saude" },
  { name: "Engenharia", alias: "engenharia" },
  { name: "Direito", alias: "direito" },
  { name: "Psicologia", alias: "psicologia" },
  { name: "Empresarial", alias: "empresarial" },
  { name: "MBA Executivo", alias: "mba-executivo" },
  { name: "Meio Ambiente", alias: "meio-ambiente" },
  { name: "Serviço Social", alias: "servico-social" },
  { name: "Soft Skills", alias: "softskills" }
];

function translateTypeCourse(typeCourse) {
  const translatedTypeCourse = {
    posGraduacao: "Pós-Graduação",
    cursosLivres: "Cursos Livres"
  };
  return translatedTypeCourse[typeCourse] || typeCourse;
}

function AllAvailableCoursesPage() {
  const [typeCourses, setTypeCourses] = useState([]);
  const [posGraduacaoCourses, setPosGraduacaoCourses] = useState([]);
  const [cursosLivresCourses, setCursosLivresCourses] = useState([]);
  const [posGraduacaoCoursesFiltered, setPosGraduacaoCoursesFiltered] =
    useState([]);
  const [cursosLivresCoursesFiltered, setCursosLivresCoursesFiltered] =
    useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState(undefined);
  const [allCourses, setAllCourses] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [actualTab, setActualTab] = useState(options[0]);
  const areaAlias = useParams().areaAlias;
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const sliderRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(true);
  const debounceHandlerRef = useRef(null);
  const fadeOutTimeoutRef = useRef(null);
  const transitionTimeoutRef = useRef(null);
  const fadeTimeoutRef = useRef(null);
  const [allStudentCourses, setAllStudentCourses] = useState([]);
  const [renderedAllCourses, setRenderedAllCourses] = useState(false);

  useEffect(() => {
    const fetchAreasInfo = async () => {
      try {
        const student = await getStudentInfos();
        let local_allStudentCourses;
        const arrayStudentCourses = await getAllStudentCourses({
          sessionId: student.sessionId
        });
        if (arrayStudentCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de seus cursos.");
          console.error(
            "Erro ao buscar informações de seus cursos - 1:",
            arrayStudentCourses.status
          );
        } else {
          let allStudentCoursesArray = [];
          if (
            arrayStudentCourses.data.coursesPosGraduation &&
            arrayStudentCourses.data.coursesPosGraduation.length > 0
          ) {
            allStudentCoursesArray = allStudentCoursesArray.concat(
              arrayStudentCourses.data.coursesPosGraduation
            );
          }
          if (
            arrayStudentCourses.data.extraCourses &&
            arrayStudentCourses.data.extraCourses.length > 0
          ) {
            allStudentCoursesArray = allStudentCoursesArray.concat(
              arrayStudentCourses.data.extraCourses
            );
          }
          local_allStudentCourses = allStudentCoursesArray;
          setAllStudentCourses(allStudentCoursesArray);
        }

        let typeCourse = "posGraduacao";

        const arrayNextSteps = await getNextStepsRequest({
          sessionId: student.sessionId
        });
        if (arrayNextSteps.status !== 200) {
          Toastify("error", "Erro ao buscar informações de cursos.");
          console.error(
            "Erro ao buscar informações de cursos:",
            arrayNextSteps
          );
        }
        try {
          if (arrayNextSteps.data !== undefined) {
            const nextStep = arrayNextSteps.data.find(
              (item) => item.type === "availableCourse"
            );
            if (nextStep && nextStep.details.type) {
              setTypeCourses(nextStep.details.type);
              switch (nextStep.details.type.length) {
                case 0:
                  window.location.href = "/dashboard";
                  break;
                case 1:
                  typeCourse = nextStep.details.type[0];
                  if (typeCourse === "posGraduacao") typeCourse = "all";
                  break;
                case 2:
                  typeCourse = "all";
                  break;
                default:
                  typeCourse = "all";
                  break;
              }
            }
          }
        } catch {}
        let arrayCourses = [];
        arrayCourses = await getAllAvailableCourses({
          sessionId: student.sessionId,
          typeCourse
        });
        if (arrayCourses.status !== 200) {
          Toastify("error", "Erro ao buscar informações de cursos.");
          console.error(
            "Erro ao buscar informações de cursos disponiveis:",
            arrayCourses.status
          );
        } else {
          let filteredAllCourses = arrayCourses.data.courses.filter(
            (course) =>
              !local_allStudentCourses.some(
                (studentCourse) => studentCourse.alias === course.alias
              )
          );

          let studentHasSpecifiedWord = false;
          let specifiedWord = "estudos";

          local_allStudentCourses.find((course) => {
            if (course.alias.includes(specifiedWord)) {
              studentHasSpecifiedWord = true;
            }
          });

          if (!studentHasSpecifiedWord) {
            filteredAllCourses = filteredAllCourses.filter(
              (course) => !course.alias.includes(specifiedWord)
            );
          }

          setAllCourses(filteredAllCourses);

          const posCourses = filteredAllCourses.filter(
            (course) => course.typeCourse === "posGraduacao"
          );

          const cursosLivres = filteredAllCourses.filter(
            (course) => course.typeCourse === "cursosLivres"
          );

          setPosGraduacaoCourses(posCourses);
          setCursosLivresCourses(cursosLivres);
          setPosGraduacaoCoursesFiltered(posCourses);
          setCursosLivresCoursesFiltered(cursosLivres);
        }

        let recommendedCoursesResponse = [];
        recommendedCoursesResponse = await getCoursesRecommendedRequest({
          sessionId: student.sessionId,
          viewAll: true
        });
        if (recommendedCoursesResponse.status !== 200) {
          Toastify(
            "error",
            "Erro ao buscar informações de cursos recomendados. - 3"
          );
          console.error(
            "Erro ao buscar informações de cursos recomendados - 4:",
            recommendedCoursesResponse.status
          );
        } else {
          const filteredRecommendedCourses =
            recommendedCoursesResponse.data.courses.filter(
              (course) =>
                !local_allStudentCourses.some(
                  (studentCourse) => studentCourse.alias === course.alias
                )
            );
          setRecommendedCourses(filteredRecommendedCourses);
        }
      } catch (error) {
        Toastify("error", "Erro generalizado ao buscar itens dos cursos.");
        console.error("Erro ao buscar itens dos cursos: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAreasInfo();
  }, [areaAlias]);

  const handleTabChange = (option) => {
    if (option === actualTab) return;
    setActualTab(option);
    setRenderedAllCourses(false);

    const index = options.findIndex((opt) => opt.alias === option.alias);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const filterCourses = (coursesToFilter) => {
    if (!coursesToFilter) return [];
    let filteredCourses = coursesToFilter;

    if (actualTab && actualTab.alias !== "all") {
      filteredCourses = filteredCourses.filter(
        (course) => course.areaAlias === actualTab.alias
      );
    }

    if (searchValue) {
      const removeAccents = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      const inputValue = removeAccents(searchValue.toLowerCase());
      filteredCourses = filteredCourses.filter((course) =>
        removeAccents(course.name.toLowerCase()).includes(inputValue)
      );
    }

    if (allStudentCourses && allStudentCourses.length > 0) {
      filteredCourses = filteredCourses.filter(
        (course) =>
          !allStudentCourses.some(
            (studentCourse) => studentCourse.alias === course.alias
          )
      );
    }

    return filteredCourses;
  };

  useEffect(() => {
    return () => {
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current);
      }
      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
    };
  }, []);

  const handleAfterChange = (current) => {
    if (current === 0) {
      setIsFirstSlide(true);
    } else {
      setIsFirstSlide(false);
    }

    const lastPossibleSlide =
      options.length - (window.innerHeight > 768 ? 8 : 2);
    console.warn(current, lastPossibleSlide);
    if (current >= lastPossibleSlide) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 3,
    draggable: true,
    swipeToSlide: true,
    autoplay: false,
    variableWidth: true,
    afterChange: (current) => handleAfterChange(current),
    arrows: false,
    touchThreshold: 15,
    edgeFriction: 0.15,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1
        }
      }
    ]
  };

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  };

  const handleCourseInput = (e) => {
    setSearchValue(e.target.value);
    setRenderedAllCourses(false);
    if (window.scrollY > window.innerHeight / 2) window.scrollTo(0, 0);
    if (actualTab.alias !== "all") handleTabChange(options[0]);
  };

  useEffect(() => {
    if (debounceHandlerRef.current) {
      clearTimeout(debounceHandlerRef.current);
    }
    if (fadeOutTimeoutRef.current) {
      clearTimeout(fadeOutTimeoutRef.current);
    }

    debounceHandlerRef.current = setTimeout(() => {
      if (!allCourses) {
        return;
      }
      setVisible(false);

      fadeOutTimeoutRef.current = setTimeout(() => {
        const filteredPosCourses = filterCourses(posGraduacaoCourses);
        const filteredCursosLivresCourses = filterCourses(cursosLivresCourses);

        setPosGraduacaoCoursesFiltered(filteredPosCourses);
        setCursosLivresCoursesFiltered(filteredCursosLivresCourses);

        setTimeout(() => {
          setRenderedAllCourses(false);
          setVisible(true);
        }, 100);
      }, 300);
    }, 500);

    return () => {
      clearTimeout(debounceHandlerRef.current);
      clearTimeout(fadeOutTimeoutRef.current);
    };
  }, [searchValue, actualTab]);

  const onRenderAllCourses = (value) => {
    setRenderedAllCourses(value);
  };

  return (
    <main className="flex flex-col justify-center w-full overflow-x-hidden bg-backgroundOne items-center text-textPrimary">
      <style jsx global>{`
        .slick-track {
          width: 1444px !important;
        }

        @media (max-width: 768px) {
          .slick-track {
            width: 1444px !important;
          }
        }
      `}</style>
      <HeaderBar
        courseNavigation={false}
        mobileMainText={"Cursos"}
        mobileBackPage="/"
        mobileMinHeaderHeight={0}
      />
      <MobileNavigationBar />
      <LoadingSection isLoading={isLoading} />
      <section className="flex flex-col items-center justify-center w-full px-6">
        <section
          className={`flex flex-col w-full max-w-[1182px] p-6 gap-3 rounded-2xl bg-backgroundTwo max-md:mt-16 mt-6 ${
            typeCourses[0] === "posGraduacao" && "pt-3"
          }`}>
          {typeCourses[0] === "posGraduacao" && (
            <div className="flex items-center gap-3 overflow-x-hidden relative">
              <div
                className={`absolute left-0 flex pl-6 items-center w-[70px] h-full`}>
                <div
                  className={` h-full absolute left-0 pointer-events-none z-[1] transition-300 ${
                    isFirstSlide && "opacity-0"
                  } w-[20px]`}
                  style={{
                    background: `linear-gradient(to right, ${certifier.colors.background.backgroundTwo} 40%, transparent)`
                  }}
                />
                <button
                  onClick={previous}
                  className={`absolute z-[2] p-3 transition-300 ${
                    isFirstSlide && "opacity-0 pointer-events-none"
                  } -ml-10`}
                  aria-label="Anterior">
                  <HiChevronLeft color="white" />
                </button>
              </div>
              <Slider ref={sliderRef} {...settings}>
                {options.map((option, index) => (
                  <div key={index} style={{ width: "fit-content" }}>
                    <button
                      className={`py-2 my-3 rounded-full transition-300 ${
                        actualTab.alias === option.alias
                          ? "bg-backgroundThree"
                          : "opacity-60"
                      } px-6`}
                      onClick={() => handleTabChange(option)}>
                      {option.name}
                    </button>
                  </div>
                ))}
              </Slider>
              <div
                className={`absolute right-0 flex justify-end pr-6 items-center w-[70px] h-full`}>
                <div
                  className={`h-full absolute right-0 pointer-events-none z-[1] transition-300 ${
                    isLastSlide && "opacity-0"
                  } w-[20px]`}
                  style={{
                    background: `linear-gradient(to left, ${certifier.colors.background.backgroundTwo} 40%, transparent)`
                  }}
                />
                <button
                  onClick={next}
                  className={`absolute z-[2] p-3 transition-300 ${
                    isLastSlide && "opacity-0 pointer-events-none"
                  } -mr-10`}
                  aria-label="Próximo">
                  <HiChevronRight color="white" />
                </button>
              </div>
            </div>
          )}
          <div className="flex max-md:flex-col w-full items-center gap-6">
            <div className="relative w-full">
              <input
                className="w-full rounded-full cursor-text px-[56px] py-3 text-sm"
                style={{
                  backgroundColor: certifier.colors.background.backgroundThree,
                  color: `white`
                }}
                placeholder="Pesquisar curso"
                onInput={handleCourseInput}
              />
              <CiSearch
                className="absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                size={24}
                color="#9B9B9B"
              />
              <style jsx>{`
                input::placeholder {
                  color: #9b9b9b;
                }
              `}</style>
            </div>
            {recommendedCourses && (
              <DiscoveryCourse courses={recommendedCourses} />
            )}
          </div>
        </section>
      </section>

      {typeCourses.length === 2 ? (
        <>
          {posGraduacaoCoursesFiltered.length > 0 && (
            <div
              className={`transition-opacity duration-300 w-full ${
                visible ? "opacity-100" : "opacity-0"
              }`}>
              <ListCourses
                isAvailableCourses={typeCourses[0]}
                disableTypeCourseSpan
                onRenderAllCourses={onRenderAllCourses}
                headerTitle="Pós-Graduação"
                allCourses={posGraduacaoCoursesFiltered}
              />
            </div>
          )}
          {cursosLivresCoursesFiltered.length > 0 && (
            <div
              className={`transition-opacity duration-300 w-full ${
                visible ? "opacity-100" : "opacity-0"
              }`}>
              <ListCourses
                isAvailableCourses={typeCourses[0]}
                disableTypeCourseSpan
                onRenderAllCourses={onRenderAllCourses}
                headerTitle="Cursos Livres"
                allCourses={cursosLivresCoursesFiltered}
              />
            </div>
          )}
          {posGraduacaoCoursesFiltered.length === 0 &&
            cursosLivresCoursesFiltered.length === 0 && (
              <div
                className={`transition-opacity duration-300 w-full ${
                  visible ? "opacity-100" : "opacity-0"
                }`}>
                <ListCourses
                  isAvailableCourses={typeCourses[0]}
                  disableTypeCourseSpan
                  onRenderAllCourses={onRenderAllCourses}
                  headerTitle={`Nenhum curso encontrado.<div class="h-[0.75rem]"></div><div class="w-full h-[1px] min-h-[1px] bg-backgroundThree rounded-full my-3" ></div><div class="h-[0.75rem]"></div>Cursos recomendados:`}
                  allCourses={recommendedCourses}
                />
              </div>
            )}
        </>
      ) : (
        <>
          {posGraduacaoCoursesFiltered.length > 0 && (
            <div
              className={`transition-opacity duration-300 w-full ${
                visible ? "opacity-100" : "opacity-0"
              }`}>
              <ListCourses
                isAvailableCourses={typeCourses[0]}
                // max16Courses={actualTab.alias === "all" && cursosLivresCoursesFiltered.length}
                disableTypeCourseSpan
                onRenderAllCourses={
                  cursosLivresCoursesFiltered.length > 0 && onRenderAllCourses
                }
                headerTitle={translateTypeCourse(
                  posGraduacaoCoursesFiltered[0].typeCourse
                )}
                allCourses={posGraduacaoCoursesFiltered}
              />
            </div>
          )}
          {cursosLivresCoursesFiltered.length > 0 &&
            (renderedAllCourses ||
              posGraduacaoCoursesFiltered.length === 0) && (
              <div
                className={`transition-opacity duration-300 w-full ${
                  visible ? "opacity-100" : "opacity-0"
                }`}>
                <ListCourses
                  isAvailableCourses={typeCourses[0]}
                  disableTypeCourseSpan
                  headerTitle={translateTypeCourse(
                    cursosLivresCoursesFiltered[0].typeCourse
                  )}
                  allCourses={cursosLivresCoursesFiltered}
                />
              </div>
            )}
          {posGraduacaoCoursesFiltered.length === 0 &&
            cursosLivresCoursesFiltered.length === 0 && (
              <div
                className={`transition-opacity duration-300 w-full ${
                  visible ? "opacity-100" : "opacity-0"
                }`}>
                <ListCourses
                  isAvailableCourses={typeCourses[0]}
                  disableTypeCourseSpan
                  headerTitle={`Nenhum curso encontrado.<div class="h-[0.75rem]"></div><div class="w-full h-[1px] min-h-[1px] bg-backgroundThree rounded-full my-3" ></div><div class="h-[0.75rem]"></div>Cursos recomendados:`}
                  allCourses={recommendedCourses}
                />
              </div>
            )}
        </>
      )}
    </main>
  );
}

export default verifyAuth(AllAvailableCoursesPage);
