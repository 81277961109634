import React, { useEffect, useState } from "react";
import "./VideoSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Autoplay,
  Parallax,
  EffectCreative
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";
import VideoClipSlide from "./VideoClipSlide";
import { certifier } from "../../mocks/certifier";
import moment from "moment";
import "moment/locale/pt-br";
import { CryptoService } from "../../services/cryptoService/crypto.service";
import axios from "axios";
import { Toastify } from "../Toastify/Toastify";
import LoadingSection from "../LoadingElements/LoadingSection";
import getStudentInfos from "../../services/getStudentInfos/getStudentInfos";

function VideoSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

 ///////////////////////////////////////////// FAZ PARTE DA GAMBIARRA - INICIO
 const [isLoading, setIsLoading] = useState(true);
 const [blockInsc, setBlockInsc] = useState(true);
 const [student, setStudent] = useState(undefined);
 const handleInsc = async () => {
   try {
     setIsLoading(true);
     const post_data = await CryptoService(
       {
         sessionId: student.sessionId,
         certifierName: certifier.generalSettings.name,
         exp: moment().add(2, "hours").unix()
       },
       "encrypt-jwt"
     );
     const config = {
       headers: {
         auth: `${post_data}`
       }
     };
     const response = await axios.get(
       `${process.env.REACT_APP_API_ADDRESS}/inscProf`,
       config
     );
     if (response.data.data === "Aluno Inscrito") {
       setBlockInsc(true);
       let localToken = await getStudentInfos();
       localToken.completedInscProfInfos = true;
       const ciphertext = await CryptoService(localToken, "encrypt-crypto");
       localStorage.setItem("user", ciphertext);
       Toastify(
         "success",
         "Sua candidatura foi enviada com sucesso! Logo, um membro de nossa equipe entrará em contato."
       );
     }
   } catch (error) {
     Toastify("error", "Erro ao enviar candidatura.");
     console.error(`erro get insc status`, error);
   } finally {
     setIsLoading(false);
   }
 };
 useEffect(() => {
   const fetchStudentInfos = async () => {
     const student = await getStudentInfos();
     try {
       if (student.completedInscProfInfos) {
         setBlockInsc(true);
       } else if (student && student.city === "São Paulo") {
         const post_data = await CryptoService(
           {
             sessionId: student.sessionId,
             certifierName: certifier.generalSettings.name,
             exp: moment().add(2, "hours").unix()
           },
           "encrypt-jwt"
         );
         const config = {
           headers: {
             auth: `${post_data}`
           }
         };
         const response = await axios.get(
           `${process.env.REACT_APP_API_ADDRESS}/getInscProf`,
           config
         );
         setBlockInsc(response.data.data === "Inscrito");
         if (response.data.data === "Inscrito") {
           let localToken = await getStudentInfos();
           localToken.completedInscProfInfos = true;
           const ciphertext = await CryptoService(
             localToken,
             "encrypt-crypto"
           );
           localStorage.setItem("user", ciphertext);
         }
       }
     } catch (error) {
       console.error(`erro get insc status`, error);
     }
     setStudent(student);
     setIsLoading(false);
   };
   fetchStudentInfos();
 }, []);
 ///////////////////////////////////////////// FAZ PARTE DA GAMBIARRA - FIM

    return (
    <div className="w-full">
      <LoadingSection isLoading={isLoading} />
      {certifier.portal.banners && certifier.portal.banners.length ? (
        <section className="swiper-cards-progress flex justify-between items-center gap-12 pb-10 max-lg:pb-0 select-none">
          <Swiper
            className={`bg-[#111216] w-full`}
            // navigation={true}
            navigation={false}
            slidesPerView={1}
            simulateTouch={false}
            // loop={true} // Disabled Loop to stop warning
            loop={
              certifier.portal.banners && certifier.portal.banners.length > 1
                ? true
                : false
            }
            pagination={{ clickable: true }}
            speed={1000}
            autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
            parallax={true}
            style={{ transitionTimingFunction: "ease-in-out" }}
            onSlideChange={handleSlideChange}
            // modules={[Navigation, Pagination, Autoplay, Parallax, EffectCreative]}
            modules={[Pagination, Autoplay, Parallax, EffectCreative]}
            effect="creative"
            creativeEffect={{
              prev: {
                translate: ["-40%", 0, 0]
              },
              next: {
                translate: ["100%", 0, 0]
              }
            }}>
            {certifier.portal.banners.map((item, index) => (
              <SwiperSlide key={index} className="relative max-h-[85vh]">
                <VideoClipSlide
                  redirectTo={item.redirectTo}
                  ///////////////////////////////////////////// FAZ PARTE DA GAMBIARRA - INICIO
                  item={
                    student && student.city === "São Paulo"
                      ? {
                          _id: "66981774f4d135001258e99c",
                          id: 0,
                          video:
                            "https://player-vz-bfac27ef-501.tv.pandavideo.com.br/embed/?v=e3fb93c2-925c-4e07-9db0-7d24aa2d10ae",
                          imageThumb:
                            "https://storage.googleapis.com/bucket-fl-storage/sandbox%2Fundefined%2Fformatura-IMG-Cover-1721243525271.webp",
                          title: "Vagas Abertas para Professores na Líbano!",
                          description:
                            "Candidate-se abaixo e em breve entraremos em contato com mais informações.",
                          buttonText: "Me Candidatar",
                          buttonLink: "",
                          buttonDisabled: blockInsc,
                          buttonFunction: handleInsc
                        }
                      : student && student.areas.includes("engenharia")
                      ? {
                          _id: "66981774f4d135001258e99c",
                          id: 0,
                          video:
                            "https://player-vz-bfac27ef-501.tv.pandavideo.com.br/embed/?v=1ba2cf6f-c265-480d-b8a0-5b590e592639",
                          imageThumb:
                            "https://storage.googleapis.com/bucket-fl-storage/sandbox%2Fundefined%2Fformatura-IMG-Cover-1721243525271.webp",
                          title:
                            "Ele se tornou um engenheiro que não depende de ninguém.",
                          description:
                            "Clique para saber como nosso aluno fez isso.",
                          buttonText: "Assista ao episódio",
                          buttonLink:
                            "https://cutt.ly/6eUnxo9C",
                          buttonDisabled: false
                        }
                      : item
                  }
                  ///////////////////////////////////////////// FAZ PARTE DA GAMBIARRA - FIM
                  currentIndex={currentIndex}
                  blankPageButton
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      ) : (
        <section className="py-16" />
      )}
    </div>
  );
}

export default VideoSlider;
